import _ from 'lodash';
import React, { useState, useEffect, MouseEvent, useRef } from 'react';
import Icon from '../../display/Icon';

interface Props {
  children: React.ReactNode;
  className?: string;
  collapsedValue?: boolean;
  contentPadding?: string;
  defaultName?: string;
  headingContent?: React.ReactNode;
  iconCodeCollapsed?: string;
  iconCodeUncollapsed?: string;
  id?: string;
  name: string;
  nameChangeOn?: boolean;
  onNameChange?: (name: string) => void;
  onReorderDown?: (e: React.MouseEvent) => void;
  onReorderUp?: (e: React.MouseEvent) => void;
  onToggle?: (arg0: boolean) => void;
  reorderOn?: boolean;
  connectToLMS?: boolean;
}

function Accordion({
  children,
  className,
  collapsedValue = true,
  contentPadding,
  headingContent,
  iconCodeCollapsed = 'add',
  iconCodeUncollapsed = 'remove',
  id,
  name,
  nameChangeOn = false,
  onNameChange = () => undefined,
  onReorderDown = () => undefined,
  onReorderUp = () => undefined,
  onToggle = () => undefined,
  reorderOn = false,
  connectToLMS = false,
}: Props): JSX.Element {
  const uniqueId = useRef(_.uniqueId());

  const [collapsed, setCollapsed] = useState(collapsedValue);
  const [editName, setEditName] = useState(false);

  const handleToggle = () =>
    setCollapsed((prev) => {
      onToggle(!prev);
      return !prev;
    });

  useEffect(() => {
    if (collapsedValue != null) setCollapsed(collapsedValue);
  }, [collapsedValue]);

  return (
    <div className={`peer-accordion${className ? ' ' + className : ''}`} id={id}>
      <div className="accordion-heading">
        <div className="heading-left-wrapper">
          <button
            className="accordion-toggle"
            type="button"
            onClick={handleToggle}
            aria-label={collapsed ? 'Expand' : 'Collapse'}
            aria-expanded={!collapsed}
            aria-controls={`accordion-content-${uniqueId.current}`}
          >
            <Icon code={collapsed ? iconCodeCollapsed : iconCodeUncollapsed} ariaHidden />
          </button>
          <h2 id={`accordion-name-${uniqueId.current}`} className={nameChangeOn ? 'sr-only' : undefined}>
            {name}
          </h2>
          {nameChangeOn ? (
            editName ? (
              <div>
                <label>
                  Name:{' '}
                  <input
                    type="text"
                    value={name}
                    onChange={(e) => onNameChange(e.target.value)}
                    onBlur={() => setEditName(false)}
                    autoFocus={true}
                  />
                </label>
              </div>
            ) : (
              <button className="accordion-name-edit" onClick={() => setEditName(true)}>
                <h2>
                  {name} <Icon code="edit" label="Click to edit" />
                </h2>
              </button>
            )
          ) : null}
        </div>

        {connectToLMS ? (
          <div className="LMS-connection">
            <Icon code="sync_alt" style={{ color: '#1fb978' }} />
            LMS
          </div>
        ) : null}

        {headingContent ? <div className="custom-heading-content">{headingContent}</div> : null}
        {reorderOn ? (
          <div className="reorder-controls">
            <div className="reorder-arrows">
              <button className="button-mini" onClick={onReorderUp}>
                <Icon code="expand_less" label="Reorder up" />
              </button>
              <button className="button-mini" onClick={onReorderDown}>
                <Icon code="expand_more" label="Reorder down" />
              </button>
            </div>
            <Icon code="reorder" />
          </div>
        ) : null}
      </div>
      <div
        id={`accordion-content-${uniqueId.current}`}
        className="accordion-contents"
        style={{ display: collapsed ? 'none' : 'flex', padding: contentPadding }}
        role="region"
        aria-labelledby={`accordion-name-${uniqueId.current}`}
      >
        {children}
      </div>
    </div>
  );
}

interface RowProps {
  children: React.ReactNode;
  className?: string;
  justify?: 'end';
}

function Row({ children, className, justify }: RowProps): JSX.Element {
  let justifyClass = '';
  switch (justify) {
    case 'end':
      justifyClass = ' justify-end';
      break;
    default:
      justifyClass = '';
  }
  return <div className={`accordion-row${className ? ' ' + className : ''}${justifyClass}`}>{children}</div>;
}

interface ColProps {
  children: React.ReactNode;
  className?: string;
  justify?: 'center';
  align?: 'center';
}

function Col({ children, className, justify, align }: ColProps): JSX.Element {
  return (
    <div
      className={`accordion-col${className ? ' ' + className : ''}${justify ? ' justify-center' : ''}${
        align ? ' align-center' : ''
      }`}
    >
      {children}
    </div>
  );
}

Accordion.Row = Row;
Accordion.Col = Col;

export default Accordion;
