import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import { Assignment, Group, User } from '../../types/types';
import {
  addUsersToGroup,
  autoCreateGroups,
  closeAllAssignmentGroups,
  closeAssignmentGroup,
  copyGroupsFromPreviousAssignment,
  createGroup,
  deleteAssignmentGroups,
  deleteGroup,
  editAssignment,
  getAssignmentData,
  getAssignmentGroups,
  getAssignmentsWithCopyableGroups,
  getGroupSyncableCategoryOptions,
  groupSync,
  importGroups,
  openAllAssignmentGroups,
  openAssignmentGroup,
  promoteUserToGroupLeader,
  removeUserFromGroup,
  syncGroupToLMS,
  updatePeerceptivGroupToLMS,
  runGroupFormation,
} from '../../utils/requests';
import Avatar from '../core/display/Avatar/Avatar';
import Accordion from '../core/layout/Accordion/Accordion';
import Button from '../core/button/Button/Button';
import Dropdown from '../core/button/Dropdown/Dropdown';
import { openModal, useModalContext } from '../../contexts/ModalContext';
import LoadingSpinner from '../core/layout/LoadingSpinner/LoadingSpinner';
import { useLocation } from 'react-router';
import {
  changeNumberInputWithBounds,
  choosePlural,
  conditionallyPlural,
  formDataToObject,
} from '../../utils/functions';
import Toggle from '../core/input/Toggle/Toggle';
import { useDispatch, useSelector } from 'react-redux';
import Icon from '../core/display/Icon';
import { formDataToObjectParsed } from '../../utils/functions';
import { selectAssignment, selectUser } from '../../store/selectors';
import { newUpdateKey, setReduxAssignment } from '../../actions';
import UploadMenu from './UploadMenu';
import HelpTag from '../core/display/HelpTag';
import AlertBar from '../core/display/AlertBar';
import { Link } from 'react-router-dom';
import { API_URL } from '../../utils/constants';
import TabList from '../core/layout/TabList/TabList';
import { Tooltip, TooltipContent, TooltipTrigger } from '../core/layout/Tooltip/Tooltip';

interface Props {
  circles?: boolean;
  saveGroup: (group: Group, callback?: () => void) => void;
}

function TeacherGroupsList({ circles, saveGroup }: Props): JSX.Element {
  const { courseId, assignmentId } = useParams() as { courseId: string; assignmentId: string };
  const [assignment, setAssignment] = useState<Assignment>(useSelector(selectAssignment) as Assignment);

  const groupType = circles ? 'Circle' : 'Group';
  const [updateKey, setUpdateKey] = useState(0);
  const [groups, setGroups] = useState<Group[]>([]);
  const [selectedId, setSelectedId] = useState(new URLSearchParams(useLocation().search).get('defaultId') || '');
  const [selectedGroup, setSelectedGroup] = useState<Group | null>(null);
  const [requestedLength, setRequestedLength] = useState(0);
  const [copyableGroupAssignments, setCopyableGroupAssignments] = useState<Assignment[]>([]);
  const [loading, setLoading] = useState(false);

  const [moving, setMoving] = useState(false);
  const [movingUser, setMovingUser] = useState<User | null>(null);
  const [movingOriginGroup, setMovingOriginGroup] = useState<Group | null>(null);

  const user = useSelector(selectUser);
  // const assignment = useSelector(selectAssignment);
  const dispatch = useDispatch();
  const { modalDispatch } = useModalContext();
  const location = useLocation();

  const basePath = useMemo(() => {
    const matches = location.pathname.match(circles ? /(\S+\/circles)/g : /(\S+\/groups)/g);
    return (matches || [''])[0];
  }, [location, circles]);

  const maxGroupSize = useMemo(() => Math.max(0, ...groups.map((group) => group.groupMembers.length)), [groups]);

  const countStudentsWithoutGroup = assignment?.progressStats?.countStudentsWithoutGroup ?? 0;

  useEffect(() => {
    getAssignmentGroups(assignmentId, (newGroups: Group[]) => {
      setGroups(newGroups);
      if (updateKey === 0) setRequestedLength(newGroups.length);
    });
  }, [assignmentId, updateKey]);

  useEffect(() => {
    getAssignmentsWithCopyableGroups(assignmentId, setCopyableGroupAssignments);
  }, [assignmentId]);

  useEffect(() => {
    getAssignmentData(assignmentId, setAssignment);
  }, [assignmentId, updateKey]);

  const forceUpdate = useCallback(() => {
    setUpdateKey((prevKey) => prevKey + 1);
  }, []);

  const requestDeleteGroup = useCallback(
    (groupId: string) => {
      deleteGroup(assignmentId, groupId, () => {
        setRequestedLength((prevLength) => prevLength - 1);
        forceUpdate();
      });
    },
    [assignmentId, forceUpdate],
  );

  const requestRemoveUserFromGroup = useCallback(
    (groupId: string, userId: string) => {
      removeUserFromGroup(assignmentId, groupId, userId, forceUpdate);
    },
    [assignmentId, forceUpdate],
  );

  const requestDeleteAssignmentGroups = useCallback(
    (assignmentId: string) => {
      deleteAssignmentGroups(assignmentId, () => {
        setRequestedLength(0);
        forceUpdate();
      });
    },
    [forceUpdate],
  );

  const requestImportGroups = useCallback(
    (groups: string[][]) => {
      importGroups(assignmentId, groups, () => {
        forceUpdate();
        dispatch(newUpdateKey()); // This request updates the Assignment
      });
    },
    [assignmentId, dispatch, forceUpdate],
  );

  const requestPromoteUserToLeader = useCallback(
    (groupId: string, userId: string) => {
      promoteUserToGroupLeader(assignmentId, groupId, userId, forceUpdate);
    },
    [assignmentId, forceUpdate],
  );

  const requestCopyGroupsFromPreviousAssignment = useCallback(
    (sourceAssignmentId: string) => {
      copyGroupsFromPreviousAssignment(assignmentId, sourceAssignmentId, forceUpdate);
    },
    [assignmentId, forceUpdate],
  );

  const requestAutoCreateGroups = useCallback(
    (reqData: { numberOfGroups: number; maxUsersPerGroup: number }) => {
      autoCreateGroups(assignmentId, reqData, () => {
        forceUpdate();
        dispatch(newUpdateKey()); // This request updates the Assignment
      });
    },
    [assignmentId, dispatch, forceUpdate],
  );

  const requestSetGroupOpenAccess = useCallback(
    (groupId: string, access: 'open' | 'closed') => {
      if (access === 'open') openAssignmentGroup(assignmentId, groupId, forceUpdate);
      if (access === 'closed') closeAssignmentGroup(assignmentId, groupId, forceUpdate);
    },
    [assignmentId, forceUpdate],
  );

  const updateGroup = useCallback(
    (group: Group) => {
      setGroups((prevGroups) => {
        let diff = true;
        const newGroups = prevGroups.map((prevGroup) => {
          if (prevGroup.groupId === group.groupId) {
            if (!_.isEqual(prevGroup, group)) return group;
            else diff = false;
          }
          return prevGroup;
        });
        if (diff) saveGroup(group, forceUpdate);
        return newGroups;
      });
    },
    [forceUpdate, saveGroup],
  );

  const initMoveState = useCallback((userToMove: User, groupOrigin: Group) => {
    setMoving(true);
    setMovingUser(userToMove);
    setMovingOriginGroup(groupOrigin);
  }, []);

  const moveUser = useCallback(
    (userId: string, groupId: string) => {
      addUsersToGroup(assignmentId, groupId, [userId], () => {
        setMoving(false);
        setSelectedId(groupId);
        forceUpdate();
      });
    },
    [assignmentId, forceUpdate],
  );

  const renderLoadingPlaceholders = () => {
    const loadingPlaceholders = [] as JSX.Element[];
    for (let i = groups.length; i < requestedLength; i++)
      loadingPlaceholders.push(
        <div key={`loading-placeholder-${i}`} id="group-add-loading-placeholder">
          <LoadingSpinner position="static" size={32} />
          <span>Loading</span>
        </div>,
      );
    return loadingPlaceholders;
  };

  const handleGroupSync = useCallback(() => {
    getGroupSyncableCategoryOptions(
      assignmentId,
      (categories) => {
        if (categories.length < 1)
          modalDispatch(
            openModal({
              heading: 'Cannot Sync',
              label: 'No Group Categories are available to sync.',
              buttonText: 'Return',
              cancelHide: true,
            }),
          );
        else
          modalDispatch(
            openModal({
              heading: `${groupType} Sync`,
              buttonText: 'Sync',
              children: (
                <div id="group-sync-menu">
                  <div className="input-wrapper">
                    <label htmlFor="group-sync-categories">{`Choose a ${groupType} Sync Category:`}</label>
                    <select id="group-sync-categories" name="categoryId" defaultValue="" required>
                      <option value="" disabled>
                        Choose a Category
                      </option>
                      {categories.map((category) => (
                        <option key={`category-${category.categoryId}`} value={category.categoryId}>
                          {category.categoryName}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              ),
              onSubmit: (formData) => {
                setLoading(true);
                const { categoryId } = formDataToObject(formData) as { categoryId: string };
                if (categoryId)
                  groupSync(
                    assignmentId,
                    categoryId,
                    (groups) => {
                      setLoading(false);
                      setRequestedLength(groups.length);
                      setGroups(groups);
                      modalDispatch(
                        openModal({
                          heading: `${groupType} Sync Successful`,
                          buttonText: 'Continue',
                          cancelHide: true,
                        }),
                      );
                    },
                    () => {
                      setLoading(false);
                      return false;
                    },
                  );
              },
            }),
          );
      },
      () => {
        modalDispatch(
          openModal({
            heading: 'Sync Error',
            label:
              'There was an error reading group categories from your learning management system. You may need to relaunch Peerceptiv and try again.',
            buttonText: 'Return',
            cancelHide: true,
          }),
        );
        return false;
      },
    );
  }, [assignmentId, groupType, modalDispatch]);

  const openPermissionsMenu = useCallback(
    () =>
      modalDispatch(
        openModal({
          heading: `Manage Permissions`,
          form: false,
          closeButton: true,
          noActionButtons: true,
          children: <PermissionsMenu updateData={forceUpdate} circles={circles} maxGroupSize={maxGroupSize} />,
        }),
      ),
    [circles, forceUpdate, maxGroupSize, modalDispatch],
  );

  const openAutoGenMenu = useCallback(() => {
    modalDispatch(
      openModal({
        heading: `Auto Generate ${groupType}s`,
        inputType: 'none',
        buttonText: 'Generate',
        children: <AutoGroupMenu circles={circles} />,
        onSubmit: (formData) => {
          const data = formDataToObject(formData) as {
            numberOfGroups?: string;
            maxUsersPerGroup?: string;
            choice?: string;
          };

          const groupOption = data.choice === 'size-option';
          const numberOfGroups = data.numberOfGroups && !groupOption ? parseInt(data.numberOfGroups) : 0;
          const maxUsersPerGroup = data.maxUsersPerGroup && groupOption ? parseInt(data.maxUsersPerGroup) : 0;
          requestAutoCreateGroups({ numberOfGroups, maxUsersPerGroup });
        },
      }),
    );
  }, [circles, groupType, modalDispatch, requestAutoCreateGroups]);

  const openCopyFromAssignmentMenu = useCallback(() => {
    modalDispatch(
      openModal({
        heading: `Copy ${groupType}s From Past Assignment`,
        inputType: 'none',
        buttonText: copyableGroupAssignments.length > 0 ? 'Copy' : 'Okay',
        cancelHide: copyableGroupAssignments.length < 1,
        children: (
          <>
            {copyableGroupAssignments.length > 0 ? (
              <div id="copy-past-group-modal-content">
                <label htmlFor="past-group-copy">{`Copy from assignment:`}</label>
                <select id="past-group-copy" name="assignmentId" defaultValue="" required>
                  <option value="" disabled>
                    Select a past assignment
                  </option>
                  {copyableGroupAssignments.map((assignment) => (
                    <option key={`assignment-${assignment.assignmentId}`} value={assignment.assignmentId}>
                      {assignment.assignmentName}
                    </option>
                  ))}
                </select>
              </div>
            ) : (
              <p>No past group assignments to copy from.</p>
            )}
          </>
        ),
        onSubmit: (formData) => {
          const { assignmentId } = formDataToObject(formData) as { assignmentId: string };
          if (assignmentId) requestCopyGroupsFromPreviousAssignment(assignmentId);
        },
      }),
    );
  }, [copyableGroupAssignments, groupType, modalDispatch, requestCopyGroupsFromPreviousAssignment]);

  const openGroupFormationModal = () =>
    modalDispatch(
      openModal({
        heading: `Re-Run Group Formation`,
        form: false,
        closeButton: true,
        cancelHide: true,
        buttonText: 'Run Group Formation',
        children: <AlertBar>This will delete any existing groups.</AlertBar>,
        onConfirm: () => {
          setLoading(true);
          runGroupFormation(assignmentId, () => {
            setLoading(false);
            forceUpdate();
            modalDispatch(
              openModal({
                heading: `Group Formation Complete`,
                form: false,
                closeButton: true,
                cancelHide: true,
                buttonText: 'Continue',
              }),
            );
          });
        },
      }),
    );

  const syncGroupToLMSHandler = useCallback(
    () =>
      modalDispatch(
        openModal({
          heading: `Sync groups in this assignment to Learning Management System`,
          closeButton: true,
          cancelHide: true,
          buttonText: 'Sync',
          form: true,
          onSubmit: (formData) => {
            const categoryName = formDataToObjectParsed(formData).category as string;
            syncGroupToLMS(
              assignmentId,
              categoryName,
              () => {
                forceUpdate();
              },
              (error) => {
                setLoading(false);
              },
            );
          },
          children: <SyncGroupToLMS />,
        }),
      ),
    [assignmentId, forceUpdate, modalDispatch],
  );

  function SyncGroupToLMS(): JSX.Element {
    return (
      <div>
        <label htmlFor="name">Category:</label>
        <input id="category" name="category" type="text" />
      </div>
    );
  }

  const getControlsBar = () => {
    return (
      <div id="ctrls-bar">
        <Button
          id="new-group-btn"
          variant="rad low"
          onClick={() => {
            setRequestedLength((prevLength) => prevLength + 1);
            createGroup(
              assignmentId,
              `${groupType} #${requestedLength + 1}`,
              () => {
                forceUpdate();
              },
              () => {
                setRequestedLength((prevLength) => prevLength - 1);
                return false;
              },
            );
          }}
        >
          New {groupType}
        </Button>
        {user.groupSyncEnabled ? (
          <Button id="group-sync-btn" variant="rad low" onClick={handleGroupSync}>
            Group Sync from LMS
          </Button>
        ) : null}
        {assignment.lmsGroupCategoryId === null ? (
          <Button variant="rad low" onClick={syncGroupToLMSHandler}>
            New Groups to LMS
          </Button>
        ) : null}
        {assignment.lmsGroupCategoryId ? (
          <Button
            variant="rad low"
            onClick={() => {
              modalDispatch(
                openModal({
                  heading: 'Overwrite LMS Groups',
                  label: `It looks like these groups are already connected to Canvas, would you like to send these groups to overwrite the existing canvas groups?`,
                  inputType: 'none',
                  buttonText: 'Yes',
                  onConfirm: () => {
                    if (assignment.lmsGroupCategoryId != null && assignment.lmsGroupCategoryName != null) {
                      updatePeerceptivGroupToLMS(
                        assignmentId,
                        assignment.lmsGroupCategoryId,
                        assignment.lmsGroupCategoryName,
                        forceUpdate,
                      );
                    }
                  },
                }),
              );
            }}
          >
            Overwrite LMS Groups
          </Button>
        ) : null}
        <Dropdown
          className="peer-button button-alt button-rad button-low"
          id="groups-more-opts-dropdown"
          buttonContent="More"
          iconCode="arrow_drop_down"
          align="left"
          top="100%"
          minWidth="200px"
        >
          {assignment?.groupFormationEnabled && assignment.status === 'COMPLETE' ? (
            <Dropdown.Link href="#" onClick={openGroupFormationModal}>
              Re-Run Group Formation
            </Dropdown.Link>
          ) : null}
          <Dropdown.Link
            onClick={() =>
              modalDispatch(
                openModal({
                  heading: `Upload ${groupType} List`,
                  form: false,
                  closeButton: true,
                  noActionButtons: true,
                  children: <UploadMenu requestImportGroups={requestImportGroups} circles={circles} sections={false} />,
                }),
              )
            }
          >
            Upload {groupType} List
          </Dropdown.Link>
          {user.groupSyncEnabled ? <Dropdown.Link onClick={handleGroupSync}>{groupType} Sync</Dropdown.Link> : null}
          <Dropdown.Link
            onClick={() => {
              modalDispatch(
                openModal({
                  heading: `Auto Generate ${groupType}s`,
                  inputType: 'none',
                  buttonText: 'Generate',
                  children: <AutoGroupMenu circles={circles} />,
                  onSubmit: (formData) => {
                    const data = formDataToObject(formData) as {
                      numberOfGroups?: string;
                      maxUsersPerGroup?: string;
                      choice: 'size-option' | 'number-option';
                    };

                    const numberOfGroups =
                      data.choice === 'number-option' && data.numberOfGroups ? parseInt(data.numberOfGroups) : 0;
                    const maxUsersPerGroup =
                      data.choice === 'size-option' && data.maxUsersPerGroup ? parseInt(data.maxUsersPerGroup) : 0;

                    requestAutoCreateGroups({ numberOfGroups, maxUsersPerGroup });
                  },
                }),
              );
            }}
          >
            Auto Generate {groupType}s
          </Dropdown.Link>
          <Dropdown.Link
            onClick={() => {
              modalDispatch(
                openModal({
                  heading: `Copy ${groupType}s From Past Assignment`,
                  inputType: 'none',
                  buttonText: copyableGroupAssignments.length > 0 ? 'Copy' : 'Okay',
                  cancelHide: copyableGroupAssignments.length < 1,
                  children: (
                    <>
                      {copyableGroupAssignments.length > 0 ? (
                        <div id="copy-past-group-modal-content">
                          <label htmlFor="past-group-copy">{`Copy from assignment:`}</label>
                          <select id="past-group-copy" name="assignmentId" defaultValue="" required>
                            <option value="" disabled>
                              Select a past assignment
                            </option>
                            {copyableGroupAssignments.map((assignment) => (
                              <option key={`assignment-${assignment.assignmentId}`} value={assignment.assignmentId}>
                                {assignment.assignmentName}
                              </option>
                            ))}
                          </select>
                        </div>
                      ) : (
                        <p>No past group assignments to copy from.</p>
                      )}
                    </>
                  ),
                  onSubmit: (formData) => {
                    const { assignmentId } = formDataToObject(formData) as { assignmentId: string };
                    if (assignmentId) requestCopyGroupsFromPreviousAssignment(assignmentId);
                  },
                }),
              );
            }}
          >
            Copy {groupType}s From Past Assignment
          </Dropdown.Link>
          <Dropdown.Link
            onClick={() =>
              modalDispatch(
                openModal({
                  heading: `Manage Permissions`,
                  form: false,
                  closeButton: true,
                  noActionButtons: true,
                  children: <PermissionsMenu updateData={forceUpdate} circles={circles} maxGroupSize={maxGroupSize} />,
                }),
              )
            }
          >
            Manage Permissions
          </Dropdown.Link>
          <Dropdown.Link href={`${API_URL}/assignment/${assignmentId}/groups/export`} newTab>
            Export Group Roster
          </Dropdown.Link>
          <Dropdown.Link
            onClick={() => {
              modalDispatch(
                openModal({
                  heading: `Delete All ${groupType}s`,
                  label: `Are you sure you want to delete all ${groupType.toLocaleLowerCase()}s?`,
                  inputType: 'none',
                  buttonText: 'Delete',
                  onConfirm: () => {
                    requestDeleteAssignmentGroups(assignmentId);
                  },
                }),
              );
            }}
          >
            Delete All {groupType}s
          </Dropdown.Link>
        </Dropdown>
      </div>
    );
  };

  return (
    <>
      <div id="group-list">
        {!moving ? (
          getControlsBar()
        ) : movingUser && movingOriginGroup ? (
          <MovingHeading
            movingUser={movingUser}
            movingOriginGroup={movingOriginGroup}
            selectedGroup={selectedGroup}
            setMoving={setMoving}
            moveUser={moveUser}
          />
        ) : null}
        {assignment?.groupFormationEnabled && assignment.status === 'COMPLETE' && countStudentsWithoutGroup > 0 ? (
          <AlertBar>
            {conditionallyPlural(countStudentsWithoutGroup, 'student')} without group.&nbsp;
            <Link to={`/course/${courseId}/assignment/${assignmentId}/groups/student-list?withoutGroup=true`}>
              Assign to group{choosePlural(countStudentsWithoutGroup)}
            </Link>
          </AlertBar>
        ) : null}
        {groups.length > 0 ? (
          groups.map((group) => (
            <GroupEntry
              key={`group-${group.groupId}`}
              group={group}
              selectedId={selectedId}
              setSelectedId={setSelectedId}
              setSelectedGroup={setSelectedGroup}
              updateGroup={updateGroup}
              basePath={basePath}
              requestDeleteGroup={requestDeleteGroup}
              requestRemoveUserFromGroup={requestRemoveUserFromGroup}
              requestPromoteUserToLeader={requestPromoteUserToLeader}
              requestSetGroupOpenAccess={requestSetGroupOpenAccess}
              moving={moving}
              movingUser={movingUser}
              movingOriginGroup={movingOriginGroup}
              moveUser={moveUser}
              numGroups={groups.length}
              initMoveState={initMoveState}
            />
          ))
        ) : (
          <div className="empty-interface">
            <h1>No Groups</h1>
            {assignment?.groupFormationEnabled ? (
              assignment.status !== 'COMPLETE' ? (
                <>
                  <p>
                    <mark>
                      This assignment contains a <b>Group Formation</b> phase.
                    </mark>{' '}
                    Please allow for this phase to conclude so groups can be automatically generated according to our
                    Group Formation algorithm.
                  </p>
                  <p>
                    After groups are generated, you may have to manually assign students to groups who did not complete
                    the Group Formation survey.
                  </p>
                </>
              ) : (
                <>
                  <p>
                    It appears that this{' '}
                    <mark>
                      <b>Group Formation</b>
                    </mark>{' '}
                    assignment has no groups after the assignment has concluded.
                  </p>
                  <p>
                    If needed, you can restart the assignment by editing its deadlines, or you can manually run the{' '}
                    <mark>
                      <b>Group Formation</b>
                    </mark>{' '}
                    algorithm using the button below:
                  </p>
                  <Button onClick={openGroupFormationModal}>Run Group Formation</Button>
                </>
              )
            ) : (
              <>
                <p>No groups exist at this time. You may choose one of the following options to create groups:</p>
                <ul>
                  <li>
                    Allow students to create their groups.{' '}
                    <Button variant="link" onClick={openPermissionsMenu}>
                      View settings
                    </Button>
                  </li>
                  <li>Create groups manually. Start by clicking the &quot;New Group&quot; button above.</li>
                  <li>
                    Use Group Sync to bring in groups from your LMS. Click &quot;More&quot; above, then &quot;Group
                    Sync.&quot;
                  </li>
                </ul>
                <div className="choice-wrapper">
                  <p>OR</p>
                  <Button variant="rad low" onClick={openAutoGenMenu}>
                    Basic Group Auto Generation
                  </Button>
                  <Button variant="rad low" onClick={openCopyFromAssignmentMenu}>
                    Copy Groups from a Past Assignment
                  </Button>
                </div>
              </>
            )}
          </div>
        )}
        {renderLoadingPlaceholders()}
      </div>
      {loading ? <LoadingSpinner /> : null}
    </>
  );
}

interface GroupEntryProps {
  basePath: string;
  group: Group;
  initMoveState: (userToMove: User, groupOrigin: Group) => void;
  moveUser: (userId: string, groupId: string) => void;
  moving: boolean;
  movingOriginGroup: Group | null;
  movingUser: User | null;
  numGroups: number;
  requestDeleteGroup: (groupId: string) => void;
  requestPromoteUserToLeader: (groupId: string, userId: string) => void;
  requestRemoveUserFromGroup: (groupId: string, userId: string) => void;
  requestSetGroupOpenAccess: (groupId: string, access: 'open' | 'closed') => void;
  setSelectedGroup: (arg0: Group | null) => void;
  selectedId: string;
  setSelectedId: React.Dispatch<React.SetStateAction<string>>;
  updateGroup: (group: Group) => void;
}

function GroupEntry({
  basePath,
  group,
  initMoveState,
  moveUser,
  moving,
  movingOriginGroup,
  movingUser,
  numGroups,
  requestDeleteGroup,
  requestPromoteUserToLeader,
  requestRemoveUserFromGroup,
  requestSetGroupOpenAccess,
  selectedId,
  setSelectedGroup,
  setSelectedId,
  updateGroup,
}: GroupEntryProps): JSX.Element {
  const { modalDispatch } = useModalContext();

  const assignment = useSelector(selectAssignment);

  const numGroupMembers = group.groupMembers ? group.groupMembers.length : 0;

  const onNameChange = useCallback(
    (groupName: string) => {
      if (groupName !== group.groupName) updateGroup({ ...group, groupName });
    },
    [group, updateGroup],
  );

  return (
    <Accordion
      id={`group-${group.groupId}`}
      className={'group-accordion' + (selectedId === group.groupId ? ' selected' : '')}
      name={group.groupName}
      nameChangeOn={!moving}
      onNameChange={onNameChange}
      collapsedValue={selectedId !== group.groupId}
      connectToLMS={group.externalGroupCategoryId != null || group.externalGroupId != null ? true : false}
      onToggle={(value) => {
        if (value) {
          setSelectedId('');
          setSelectedGroup(null);
        } else {
          setSelectedId(group.groupId);
          setSelectedGroup(group);
        }
      }}
      contentPadding="0.5rem"
      headingContent={
        <>
          <span className="group-size-indicator" role="img" aria-label={`${numGroupMembers} Members`}>
            <Icon code="person" ariaHidden />
            <span>{numGroupMembers}</span>
          </span>
          {assignment?.allowStudentGroupManagement ? <span>{group.openAccess ? 'Open' : 'Invite-Only'}</span> : null}
          {!moving ? (
            <Dropdown iconCode="more_vert" align="right" minWidth="200px">
              <Dropdown.Link
                onClick={() => requestSetGroupOpenAccess(group.groupId, group.openAccess ? 'closed' : 'open')}
              >
                Toggle Access {group.openAccess ? 'Closed' : 'Open'}
              </Dropdown.Link>
              <Dropdown.Link
                onClick={() => {
                  modalDispatch(
                    openModal({
                      heading: 'Delete Group',
                      label: `Are you sure you want to delete "${group.groupName}"?`,
                      inputType: 'none',
                      buttonText: 'Delete',
                      onConfirm: () => {
                        requestDeleteGroup(group.groupId);
                      },
                    }),
                  );
                }}
              >
                Delete
              </Dropdown.Link>
            </Dropdown>
          ) : null}
        </>
      }
    >
      <ul aria-label="Group Members">
        {(group.groupMembers || [])
          .sort((a, b) => (a.user.name || '').localeCompare(b.user.name || ''))
          .map((member) => (
            <li key={`member-${member.groupMemberId}`}>
              <div
                className="member-entry"
                style={moving && movingUser && movingUser.userId === member.user.userId ? { opacity: 0.3 } : undefined}
              >
                <Avatar user={member.user} size={40} />
                <span className="entry-name">
                  {member.groupLeader && assignment?.enableGroupLeaders ? (
                    <Tooltip>
                      <TooltipTrigger
                        tag="span"
                        className="leader-icon"
                        role="img"
                        tabIndex={0}
                        ariaLabel="Group Leader"
                      >
                        <Icon code="stars" label="Group Leader" />
                      </TooltipTrigger>
                      <TooltipContent>Group Leader</TooltipContent>
                    </Tooltip>
                  ) : null}
                  <span>{member.user.name}</span>
                </span>
                {!moving ? (
                  <Dropdown iconCode="expand_more" align="left">
                    {numGroups > 1 ? (
                      <Dropdown.Link
                        onClick={() => {
                          initMoveState(member.user, group);
                        }}
                      >
                        Move
                      </Dropdown.Link>
                    ) : null}
                    {!member.groupLeader && assignment?.enableGroupLeaders ? (
                      <Dropdown.Link
                        onClick={() => {
                          requestPromoteUserToLeader(group.groupId, member.user.userId);
                        }}
                      >
                        Promote to Leader
                      </Dropdown.Link>
                    ) : null}
                    <Dropdown.Link
                      onClick={() => {
                        modalDispatch(
                          openModal({
                            heading: 'Remove From Group',
                            label: `Are you sure you want to remove "${member.user.name}" from "${group.groupName}"?`,
                            inputType: 'none',
                            buttonText: 'Remove',
                            onConfirm: () => {
                              requestRemoveUserFromGroup(group.groupId, member.user.userId);
                            },
                          }),
                        );
                      }}
                    >
                      Remove
                    </Dropdown.Link>
                  </Dropdown>
                ) : null}
              </div>
            </li>
          ))}
      </ul>
      {group.groupMembers.length < (assignment?.groupSizeLimit ?? 100) ? (
        !moving ? (
          <Button className="add-members-btn" href={`${basePath}/${group.groupId}/add`} route>
            <Icon code="add" ariaHidden />
            <span>Add Members</span>
          </Button>
        ) : movingOriginGroup && movingOriginGroup.groupId !== group.groupId ? (
          <Button
            className="move-here-btn"
            onClick={() => {
              if (movingUser) moveUser(movingUser.userId, group.groupId);
            }}
          >
            <Icon code="arrow_forward" ariaHidden />
            <span>Move Here</span>
          </Button>
        ) : null
      ) : null}
    </Accordion>
  );
}

interface MovingHeadingProps {
  moveUser: (userId: string, groupId: string) => void;
  movingOriginGroup: Group;
  movingUser: User;
  selectedGroup: Group | null;
  setMoving: (arg0: boolean) => void;
}

function MovingHeading({
  moveUser,
  movingOriginGroup,
  movingUser,
  selectedGroup,
  setMoving,
}: MovingHeadingProps): JSX.Element {
  return (
    <>
      <div id="moving-heading">
        <button
          className="button-mini exit-btn"
          onClick={() => {
            setMoving(false);
          }}
        >
          <Icon code="close" label="Cancel" />
        </button>
        <h2>Moving Student</h2>
      </div>
      <p id="selected-group">
        <span className="name">
          Moving <b>{movingUser.name}</b>:
        </span>
        <span className="groups">
          <b className="old-group">{movingOriginGroup.groupName}</b>
          <Icon code="arrow_forward" label="To" />
          {selectedGroup ? (
            <b className={selectedGroup.groupId !== movingOriginGroup.groupId ? 'new-group' : 'old-group'}>
              {selectedGroup.groupName}
            </b>
          ) : null}
        </span>
      </p>
      <Button
        id="confirm-move-btn"
        variant="rad"
        onClick={() => {
          if (selectedGroup) moveUser(movingUser.userId, selectedGroup.groupId);
        }}
        disabled={selectedGroup === null || selectedGroup.groupId === movingOriginGroup.groupId}
      >
        Confirm Move
      </Button>
    </>
  );
}

interface AutoGroupMenuProps {
  circles?: boolean;
}

function AutoGroupMenu({ circles }: AutoGroupMenuProps): JSX.Element {
  const assignment = useSelector(selectAssignment) as Assignment;
  const [maxUsersPerGroup, setMaxUsersPerGroup] = useState(Math.min(5, assignment.groupSizeLimit ?? 100));
  const [currTabId, setCurrTabId] = useState('');

  const groupType = circles ? 'Circle' : 'Group';

  return (
    <div id="auto-group-menu">
      <p id="explainer">Choose a parameter by which to generate {groupType.toLocaleLowerCase()}s:</p>

      <input type="hidden" name="choice" value={currTabId} />

      <TabList
        mini2
        label="Auto Generation Settings Menu"
        tabs={
          <>
            <TabList.Tab id="size-option" controls="size-option-tab">
              {groupType} Size
            </TabList.Tab>
            <TabList.Tab id="number-option" controls="number-option-tab">
              {groupType} Number
            </TabList.Tab>
          </>
        }
        onTabChange={setCurrTabId}
      >
        <TabList.TabPanel id="size-option-tab" labeledBy="size-option">
          <label htmlFor="maxUsersPerGroup">Max students per {groupType.toLocaleLowerCase()}:</label>
          <input
            type="number"
            id="maxUsersPerGroup"
            name="maxUsersPerGroup"
            min={1}
            max={assignment.groupSizeLimit}
            value={maxUsersPerGroup}
            onChange={(e) =>
              changeNumberInputWithBounds(e.target.value, 2, assignment.groupSizeLimit ?? 100, setMaxUsersPerGroup)
            }
          />
        </TabList.TabPanel>
        <TabList.TabPanel id="number-option-tab" labeledBy="number-option">
          <label htmlFor="numberOfGroups">Number of {groupType.toLocaleLowerCase()}s:</label>
          <input type="number" min={1} defaultValue={10} id="numberOfGroups" name="numberOfGroups" />
        </TabList.TabPanel>
      </TabList>
    </div>
  );
}

interface PermissionsMenuProps {
  circles?: boolean;
  maxGroupSize: number;
  updateData: () => void;
}

function PermissionsMenu({ circles, maxGroupSize, updateData }: PermissionsMenuProps): JSX.Element {
  const assignment = useSelector(selectAssignment) as Assignment;
  const dispatch = useDispatch();

  const [groupSizeLimit, setGroupSizeLimit] = useState(assignment.groupSizeLimit);

  const requestEditAssignment = useCallback(
    (data: {
      allowStudentGroupManagement?: boolean;
      enableGroupLeaders?: boolean;
      anonymousGroups?: boolean;
      groupSizeLimit?: number;
    }) =>
      editAssignment(assignment.assignmentId ?? '', { ...assignment, ...data }, (assignment) =>
        dispatch(setReduxAssignment(assignment)),
      ),
    [assignment, dispatch],
  );

  const debouncedEditAssignment = useMemo(() => _.debounce(requestEditAssignment, 500), [requestEditAssignment]);

  const minGroupLimit = Math.max(1, maxGroupSize);
  const groupType = circles ? 'Circle' : 'Group';
  const groupTypeLow = groupType.toLocaleLowerCase();
  return (
    <div id="group-permissions-menu">
      <div className="permission">
        <Button variant="low sm" onClick={() => openAllAssignmentGroups(assignment.assignmentId ?? '', updateData)}>
          Open All {groupType}s
        </Button>
        <Button variant="low sm" onClick={() => closeAllAssignmentGroups(assignment.assignmentId ?? '', updateData)}>
          Close All {groupType}s
        </Button>
        <HelpTag>
          <b>Open {groupType}s</b> allow students to join freely and <b>Closed {groupType}s</b> require{' '}
          {!circles
            ? `${groupTypeLow} join requests to
        be accepted by the group leader. However, if group leaders are not
        granted permissions, then Closed ${groupType}s require`
            : ''}{' '}
          the instructor to assign {groupTypeLow} members.
        </HelpTag>
      </div>
      <div className="permission">
        <label htmlFor="groupSizeLimit">Group Size Limit:</label>
        <input
          id="groupSizeLimit"
          type="number"
          placeholder="Default: 100"
          min={minGroupLimit}
          max={100}
          value={groupSizeLimit}
          onChange={(e) =>
            changeNumberInputWithBounds(e.target.value, minGroupLimit, 100, (newValue: number) => {
              setGroupSizeLimit(newValue);
              debouncedEditAssignment({ groupSizeLimit: newValue });
            })
          }
        />
      </div>
      {!assignment.groupFormationEnabled ? (
        <div className="permission">
          <Toggle
            checked={assignment.allowStudentGroupManagement}
            onChange={(e) => requestEditAssignment({ allowStudentGroupManagement: e.target.checked })}
          >
            Allow Student {groupType} Self-Management
          </Toggle>
        </div>
      ) : null}
      <div className="permission">
        <Toggle
          checked={assignment.anonymousGroups}
          onChange={(e) => requestEditAssignment({ anonymousGroups: e.target.checked })}
        >
          Make {groupType} Peers Anonymous for Students
        </Toggle>
      </div>
      {!assignment.reviewingCirclesEnabled ? (
        <div className="permission">
          <Toggle
            checked={assignment.enableGroupLeaders}
            onChange={(e) => requestEditAssignment({ enableGroupLeaders: e.target.checked })}
            disabled={assignment.anonymousGroups}
          >
            Grant {groupType} Leaders Permissions{' '}
          </Toggle>
          <HelpTag>
            If group leader permissions are granted, Peerceptiv or the instructor can assign an individual to a group
            leader role and this person will be able to accept or reject group member join requests.
          </HelpTag>
        </div>
      ) : null}
    </div>
  );
}

export default TeacherGroupsList;
